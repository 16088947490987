import { request } from '@/utils'


export function initMenus(params) {
    return request({
        url: '/json/nav/menus.json',
        method: 'get',
        params: params
    })
}
export function initLinks(params) {
    return request({
        url: '/json/nav/links.json',
        method: 'get',
        params: params
    })
}

export function listLinks(params) {
    return request({
        url: '/json/nav/links.json',
        method: 'get',
        params: params
    })
}

<template>
    <div class="app-load-content flex justify-center items-center">
        <n-spin>
            <template #description>
                页面正在加载...
            </template>
        </n-spin>
    </div>
</template>
<style scoped>
.app-load-content {
    width: 100%;
    height: 100%;
}
</style>
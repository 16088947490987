import { request } from '@/utils'


export function hitokoto(params) {
    return request({
        url: '/json/nav/hitokoto.json',
        method: 'get',
        params: params
    })
}

export function webInfo(params) {
    return request({
        url: '/json/nav/app.json',
        method: 'get',
        params: params
    })
}
